import dayjs from 'dayjs';

import { addHours, format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const getDate = date => {
  if (date) {
    return new Date(date).toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
  } else {
    return new Date().toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
  }
};
/**
 * Retorna um objeto de data DayJS, levando em consideração as opções fornecidas.
 * @param {Object} options - Opções
 * @param {dayjs.Dayjs | Date | String } options.date - Data a ser utilizada. Considera a data atual caso não seja fornecida
 * @param {string} options.fromFormat - Formato original de `date`. Use se `date` é uma string mas não tem {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format|formato padronizado}. Para informações de formatação de data veja: {@link https://day.js.org/docs/en/display/format#list-of-all-available-formats}
 * @example <caption>Exemplo utilizando um formato padronizado</caption>
 * const dateText = '2021-10-11T14:40:00';
 * const dateObject = getDateObject({ date:dateText });
 * @example <caption>Exemplo utilizando um formato personalizado</caption>
 * const dateText = '03/02/2021 às 22:00';
 * const dateObject = getDateObject({
 *  date:dateText,
 *  fromFormat:'DD/MM/YYYY [às] HH:mm'
 * });
 * @returns {dayjs.Dayjs} O objeto de data DayJS
 */
export const getDateObject = options => {
  const { date, fromFormat } = Object.assign(
    {
      date: undefined,
      fromFormat: undefined
    },
    options
  );
  if (!date) {
    return dayjs().utc();
  }
  return dayjs(date, fromFormat).utc();
};

/**
 * Retorna uma string representativa de uma data fornecida.
 * @param {Object} options - Opções
 * @param {dayjs.Dayjs | Date | String } options.date - Data a ser utilizada. Considera a data atual caso não seja fornecida.
 * @param {string} options.format - Formato desejado da string. Utiliza por padrão o {@link https://day.js.org/docs/en/display/as-iso-string#docsNav|formato ISO}. Para informações de formatação de data veja: {@link https://day.js.org/docs/en/display/format#list-of-all-available-formats}
 * @param {string} options.fromFormat - Formato original de `date`. Use se `date` é uma string mas não tem {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format|formato padronizado}. Para informações de formatação de data veja: {@link https://day.js.org/docs/en/display/format#list-of-all-available-formats}
 * @example <caption> Exemplo utilizando um formato padronizado </caption>
 * const dateText = '2021-10-11T14:40:00';
 * const formattedDate = formatDate({
 * date:dateText,
 * format:'DD/MM/YYYY [às] HH:mm:ss'
 * }); // "11/10/2021 às 14:40"
 *
 * @example <caption> Exemplo utilizando um formato personalizado </caption>
 * const dateText = '11/12/2021';
 * const formattedDate = formatDate({
 * date:dateText,
 * format:'YYYY-MM-DD',
 * fromFormat:'DD/MM/YYYY'
 * }); // "2021-12-11"
 * @returns {string} Texto gerado pela formatação.
 */
export const formatDate = options => {
  const { date, format, fromFormat } = Object.assign(
    {
      date: getDateObject(),
      format: undefined,
      fromFormat: undefined
    },
    options
  );
  if (!fromFormat) {
    return dayjs(date).format(format);
  }
  return dayjs(date, fromFormat).format(format);
};
/**
 * Compara duas datas.
 * @param {dayjs.Dayjs | Date | String} a Data A
 * @param {dayjs.Dayjs | Date | String} b Data B
 * @returns {number} Valor em segundos da diferença de tempo entre as datas. Maior que 0 se `a`>`b`, menor que 0 se `a`<`b` e 0 se `a`===`b`.
 */
export const compareDate = (a, b) => {
  const aIsInvalid = !dayjs(a).isValid();
  const bIsInvalid = !dayjs(b).isValid();
  if (aIsInvalid && bIsInvalid) {
    return 0;
  }
  if (aIsInvalid) {
    return 1;
  }
  if (bIsInvalid) {
    return -1;
  }
  return dayjs(getDateObject({ date: a })).diff(
    getDateObject({ date: b }),
    'seconds'
  );
};

// Converte uma variavel do tipo Date para o formato ano-mês-dia
/**
 *
 * @param {Date|number} date
 * @returns {string}
 */
export const formatDateFromDate = date => format(date, 'yyyy-MM-dd');

/**
 *
 * @param {Date | number} date
 * @returns {string}
 */
export const formatDateWithSlash = date => format(date, 'dd/MM/yyyy');

/**
 * Formata um timestamp para exibição com data, hora e fuso horário específico
 * @param {number | Date} timestamp - Timestamp Unix em milissegundos
 * @param {number} timezone - Fuso horário em horas (ex: -3 para GMT-3, -5 para GMT-5)
 * @returns {string} Data formatada como "dd/MM/yyyy 'às' HH:mm:ss"
 */
export const formatDateWithSlashAndHours = (timestamp, timezone = -3) => {
  const date = dayjs(timestamp).utcOffset(timezone * 60);
  return date.format('DD/MM/YYYY [às] HH:mm:ss');
};

// Converte uma variavel do tipo String para o formato dia/mês/ano
export const formatDateFromString = date =>
  format(parseISO(date), 'dd/MM/yyyy ');

/**
 * Ajusta o timezone e formata a data para exibição
 * @param {number} aDate - Timestamp Unix em milissegundos
 * @param {number} originalTimezone - Fuso horário original do dado (ex: -5 para GMT-5)
 * @returns {string} Data formatada como "dd/MM/yyyy 'às' HH:mm:ss"
 */
export const formatDateWithoutTimezone = (
  aDate,
  needThreeHours = false,
  timezone = -3
) => {
  try {
    const date = new Date(aDate);

    if (needThreeHours) {
      date.setHours(date.getHours() + 3);
    }
    // Formata a data usando toLocaleString com opções específicas
    const formattedDate = date
      .toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'UTC'
        // Isso vai manter o horário como está no banco
        // por que o bendito do antigo desenvolvedor salvou a data como utc mas com o fuso local
        // fica documentado pro proximo que pegar isso entender, pq eu passei muita raiva com isso aqui
        //e to poupando vc de passar raiva.
      })
      .replace(',', ' às');

    return formattedDate;
  } catch (error) {
    throw error;
  }
};

/**
 * Ajusta o timestamp considerando o fuso original
 * @param {number} aDate - Timestamp Unix em milissegundos
 * @returns {number} Timestamp Unix em milissegundos corrigido
 */
export const setTimezone = aDate => {
  try {
    const date = new Date(aDate);

    return date.getTime();
  } catch (error) {
    throw error;
  }
};
